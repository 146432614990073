@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Golos Text";
  font-weight: 900;
  src: url("./resources/fonts/GolosText-Black.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Golos Text";
  font-weight: 800;
  src: url("./resources/fonts/GolosText-ExtraBold.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Golos Text";
  font-weight: 700;
  src: url("./resources/fonts/GolosText-Bold.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Golos Text";
  font-weight: 600;
  src: url("./resources/fonts/GolosText-SemiBold.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Golos Text";
  font-weight: 500;
  src: url("./resources/fonts/GolosText-Medium.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Golos Text";
  font-weight: 400;
  src: url("./resources/fonts/GolosText-Regular.ttf") format("opentype");
  font-display: swap;
}

* {
  scroll-behavior: smooth;
  scroll-padding-top: 86px;
  font-family: "Golos Text", Arial, Helvetica, sans-serif;
}

@media (min-width: 768px) {
  * {
    scroll-padding-top: 95px;
  }
}